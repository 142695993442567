import axiosInstances from '@/libs/axios-instances'

export default function userPermissions() {
  const getUserPermissionsInEntity = (username, entityId) => axiosInstances.portalUsers.get(`v1/permissions/${username}/${entityId}`, {
    params: {
      formatted: 1,
    },
  }).then(res => {
    const { permissions } = res.data.data
    return permissions
  })

  return {
    getUserPermissionsInEntity,
  }
}
