import { ref } from '@vue/composition-api'

export default function formatPermissions() {
  const formattedGroupedPermissions = ref([])

  const resetPermissions = () => { formattedGroupedPermissions.value = [] }

  const formatGroupedPermissions = permissions => {
    resetPermissions()
    Object.entries(permissions).forEach(([permissionsGroup, permissionsList]) => {
      formattedGroupedPermissions.value.push({
        mode: 'span', // span means this header will span all columns
        html: false,
        label: permissionsGroup,
        children: [
          ...permissionsList,
        ],
      })
    })
  }

  return {
    formatGroupedPermissions,
    formattedGroupedPermissions,
  }
}
