<template>
  <div class="mb-2">
    <!-- table -->
    <vue-good-table
      ref="my-table"
      :row-style-class="rowStyleClassFn"
      :columns="columns"
      :rows="permissions"
      :select-options="{
        enabled: true,
        disableSelectInfo: true, // disable the select info panel on top
      }"
      :group-options="{
        enabled: true,
        collapsable: true
      }"
      :sort-options="{
        enabled: false,
      }"
      @on-row-click="rowClick"
    >
      <template
        #table-header-row="props"
      >
        <span
          class="text-capitalize"
        >
          {{ props.row.label }}
        </span>
        <span>
          <b-button
            size="sm"
            class="d-block ml-auto"
            variant="primary"
            @click.stop="groupClick(props)"
          >
            Assign Group
          </b-button>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
  },
  props: {
    permissions: { type: Array, default: () => [] },
  },
  data() {
    return {
      columns: [
        {
          label: 'Permissions',
          field: 'description',
          formatFn: this.capitalizeString,
        },
      ],
    }
  },
  methods: {
    rowClick(selectedPermission) {
      const permissionId = selectedPermission.row.id
      const isPermissionSelected = selectedPermission.row.vgtSelected
      if (isPermissionSelected) this.$emit('assignPermission', permissionId)
      else this.$emit('revokePermission', permissionId)
    },
    groupClick(group) {
      this.selectGroupPermissions(group.row.children)
      const permissionsId = group.row.children.map(permission => permission.id)
      this.$emit('assignPermissionsGroup', permissionsId)
    },
    selectGroupPermissions(permissionsList) {
      permissionsList.forEach(permission => {
        this.$set(permission, 'vgtSelected', true)
      })
    },
    rowStyleClassFn(row) {
      return row.children?.find(ele => ele.vgtSelected === true) ? 'green-border' : ''
    },
    capitalizeString(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
}
</script>

<style lang="scss">
@import "src/@core/scss/vue/libs/vue-good-table.scss";
.green-border{
  border: 4px solid rgb(67, 168, 67) !important;
}
thead th.vgt-checkbox-col {
  visibility:hidden !important
}
</style>
