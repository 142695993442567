var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('vue-good-table',{ref:"my-table",attrs:{"row-style-class":_vm.rowStyleClassFn,"columns":_vm.columns,"rows":_vm.permissions,"select-options":{
      enabled: true,
      disableSelectInfo: true, // disable the select info panel on top
    },"group-options":{
      enabled: true,
      collapsable: true
    },"sort-options":{
      enabled: false,
    }},on:{"on-row-click":_vm.rowClick},scopedSlots:_vm._u([{key:"table-header-row",fn:function(props){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(props.row.label)+" ")]),_c('span',[_c('b-button',{staticClass:"d-block ml-auto",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.groupClick(props)}}},[_vm._v(" Assign Group ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }