import axiosInstances from '@/libs/axios-instances'

export default function allPermissions() {
  const getAllPermissions = () => axiosInstances.portalUsers.get('v1/permissions/all', {
    params: {
      formatted: 1,
    },
  }).then(res => {
    const { permissions } = res.data.data
    return permissions
  })

  return {
    getAllPermissions,
  }
}
